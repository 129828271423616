var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "new-document",
        title: "New Document",
        "no-close-on-backdrop": "",
        "hide-footer": "",
        size: "md",
      },
      on: { hide: _vm.resetModal },
    },
    [
      _c(
        "div",
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.showAlert,
                callback: function ($$v) {
                  _vm.showAlert = $$v
                },
                expression: "showAlert",
              },
            },
            [_c("b", [_vm._v("You must select a document to continue.")])]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "p-3" },
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-file", {
                    attrs: {
                      state: Boolean(_vm.file),
                      placeholder: "Choose a file or drop it here...",
                      "drop-placeholder": "Drop file here...",
                    },
                    model: {
                      value: _vm.file,
                      callback: function ($$v) {
                        _vm.file = $$v
                      },
                      expression: "file",
                    },
                  }),
                  _c("div", { staticClass: "mt-3" }, [
                    _vm._v(
                      "Selected file: " + _vm._s(_vm.file ? _vm.file.name : "")
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { id: "document-title", label: "Title:" } },
                [
                  _c("b-form-input", {
                    model: {
                      value: _vm.document.document_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.document, "document_title", $$v)
                      },
                      expression: "document.document_title",
                    },
                  }),
                ],
                1
              ),
              _vm.idType != "practice"
                ? _c(
                    "b-form-group",
                    { attrs: { id: "document-type", label: "Type:" } },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.types },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function () {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null, disabled: "" } },
                                    [_vm._v("-- Please select an option --")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1780735698
                        ),
                        model: {
                          value: _vm.document.document_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.document, "document_type", $$v)
                          },
                          expression: "document.document_type",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "document-submit-btn",
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }