var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mode != "table"
    ? _c("b-card", { staticClass: "shadow" }, [
        !_vm.hideDropdown
          ? _c(
              "div",
              { staticClass: "d-flex flex-wrap justify-content-end" },
              [
                _c(
                  "b-dropdown",
                  {
                    staticClass: "avo-action-dropdown",
                    staticStyle: { "min-width": "none" },
                    attrs: { "no-caret": "", right: true },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("b-icon", { attrs: { icon: "three-dots" } }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2038261878
                    ),
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.viewEditTitleModal()
                          },
                        },
                      },
                      [_vm._v("Rename")]
                    ),
                    (
                      _vm.document.filename
                        ? _vm.document.filename.split(".").pop() == "pdf"
                        : false
                    )
                      ? _c(
                          "b-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.getDownloadUrl(true)
                              },
                            },
                          },
                          [_vm._v("Preview")]
                        )
                      : _vm._e(),
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.deleteDocument()
                          },
                        },
                      },
                      [_vm._v("Delete")]
                    ),
                  ],
                  1
                ),
                _c(
                  "b-modal",
                  {
                    attrs: {
                      id: "rename-modal-" + _vm.document.document_id,
                      "hide-footer": "",
                      "hide-header": "",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "avo-header-text",
                        staticStyle: { "text-align": "center" },
                      },
                      [_vm._v(" Rename document ")]
                    ),
                    _c("hr"),
                    _c(
                      "b-form-group",
                      { attrs: { id: "document-title", label: "New title:" } },
                      [
                        _c("b-form-input", {
                          model: {
                            value: _vm.new_title,
                            callback: function ($$v) {
                              _vm.new_title = $$v
                            },
                            expression: "new_title",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mt-4",
                        staticStyle: { "text-align": "center" },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "avo-primary-btn mr-2",
                            on: {
                              click: function ($event) {
                                return _vm.submitNewTitle()
                              },
                            },
                          },
                          [_vm._v("Submit")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "avo-basic-btn ml-2",
                            on: {
                              click: function ($event) {
                                return _vm.closeEditTitleModal()
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "doc-card-body",
            staticStyle: { "min-height": "180px" },
          },
          [
            _c(
              "div",
              { staticClass: "d-flex flex-column align-items-center w-100" },
              [
                _vm.document.title
                  ? _c("div", { staticClass: "doc-title mb-3" }, [
                      _vm._v(_vm._s(_vm.document.title)),
                    ])
                  : _c("br"),
                _c("b-icon", {
                  staticClass: "mb-2",
                  attrs: {
                    icon: _vm.getFileIcon(_vm.document.filename),
                    "font-scale": "3",
                  },
                }),
                _c("div", { staticClass: "filename-link w-100" }, [
                  _c(
                    "a",
                    {
                      attrs: { role: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.getDownloadUrl()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.document.filename))]
                  ),
                ]),
                _c(
                  "div",
                  [
                    _vm.document.type_label != "unknown"
                      ? _c("b-badge", [
                          _vm._v("#" + _vm._s(_vm.document.type_label)),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column justify-content-end align-items-center created-text",
              },
              [
                _c("div", [_vm._v("Created by " + _vm._s(_vm.document.name))]),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      new Date(_vm.document.created).toLocaleString("en-US", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                      })
                    )
                  ),
                ]),
              ]
            ),
          ]
        ),
      ])
    : _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            { attrs: { cols: "1" } },
            [
              _c("b-icon", {
                attrs: {
                  icon: _vm.getFileIcon(_vm.document.filename),
                  "font-scale": "2",
                },
              }),
            ],
            1
          ),
          _vm.document.title
            ? _c(
                "b-col",
                {
                  staticClass: "d-flex flex-column",
                  staticStyle: {
                    overflow: "hidden",
                    "text-overflow": "ellipsis",
                    "text-align": "left",
                  },
                  attrs: { cols: !_vm.hideDropdown ? 8 : 10 },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "doc-title",
                      staticStyle: {
                        "text-align": "left",
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.document.title) + " ")]
                  ),
                  _c("div", { staticClass: "avo-text-light" }, [
                    _vm.document.filename && _vm.document.filename.includes(".")
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.document.filename.split(".").pop()) +
                              " "
                          ),
                          _c("span", { staticClass: "ml-1 mr-1" }, [
                            _vm._v(" | "),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(
                      " Uploaded on " +
                        _vm._s(
                          new Date(_vm.document.created).toLocaleString(
                            "en-US",
                            {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            }
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("b-col", { staticClass: "filename-link", attrs: { cols: "1" } }, [
            _c(
              "a",
              {
                attrs: { role: "button" },
                on: {
                  click: function ($event) {
                    return _vm.getDownloadUrl()
                  },
                },
              },
              [
                _c("b-icon", {
                  staticClass: "mt-2",
                  attrs: { icon: "download", "font-scale": "2" },
                }),
              ],
              1
            ),
          ]),
          !_vm.hideDropdown
            ? _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "avo-action-dropdown",
                      staticStyle: { "min-width": "none" },
                      attrs: { "no-caret": "", right: true },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("b-icon", { attrs: { icon: "three-dots" } }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2038261878
                      ),
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.viewEditTitleModal()
                            },
                          },
                        },
                        [_vm._v("Rename")]
                      ),
                      (
                        _vm.document.filename
                          ? _vm.document.filename.split(".").pop() == "pdf"
                          : false
                      )
                        ? _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.getDownloadUrl(true)
                                },
                              },
                            },
                            [_vm._v("Preview")]
                          )
                        : _vm._e(),
                      _vm.idType == "report"
                        ? _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.viewReportDocumentAssignmentModal()
                                },
                              },
                            },
                            [_vm._v("Manage Assignments")]
                          )
                        : _vm._e(),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.deleteDocument()
                            },
                          },
                        },
                        [_vm._v("Delete")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "rename-modal-" + _vm.document.document_id,
                        "hide-footer": "",
                        "hide-header": "",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "avo-header-text",
                          staticStyle: { "text-align": "center" },
                        },
                        [_vm._v(" Rename document ")]
                      ),
                      _c("hr"),
                      _c(
                        "b-form-group",
                        {
                          attrs: { id: "document-title", label: "New title:" },
                        },
                        [
                          _c("b-form-input", {
                            model: {
                              value: _vm.new_title,
                              callback: function ($$v) {
                                _vm.new_title = $$v
                              },
                              expression: "new_title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mt-4",
                          staticStyle: { "text-align": "center" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "avo-primary-btn mr-2",
                              on: {
                                click: function ($event) {
                                  return _vm.submitNewTitle()
                                },
                              },
                            },
                            [_vm._v("Submit")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "avo-basic-btn ml-2",
                              on: {
                                click: function ($event) {
                                  return _vm.closeEditTitleModal()
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }