var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0 pt-3", staticStyle: { background: "white" } },
    [
      _c(
        "div",
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.showAlert,
                callback: function ($$v) {
                  _vm.showAlert = $$v
                },
                expression: "showAlert",
              },
            },
            [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
          ),
        ],
        1
      ),
      _c(
        "b-overlay",
        {
          staticStyle: { "min-height": "200px" },
          attrs: { show: _vm.documentsLoading, variant: "white" },
        },
        [
          _vm.mode === "list"
            ? _c(
                "b-row",
                {
                  staticClass: "px-5",
                  attrs: { "align-v": "center", "align-h": "between" },
                },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { sm: "12", md: "7", lg: "6" },
                    },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "search-group" },
                        [
                          _c("b-input-group-prepend", [
                            _c(
                              "span",
                              { staticClass: "input-group-text" },
                              [_c("b-icon", { attrs: { icon: "search" } })],
                              1
                            ),
                          ]),
                          _c("b-form-input", {
                            staticClass: "document-search-input pl-1",
                            attrs: {
                              type: "search",
                              placeholder: "Try typing a filename or title",
                            },
                            on: {
                              keyup: _vm.debounceDocuments,
                              search: _vm.debounceDocuments,
                            },
                            model: {
                              value: _vm.search.terms,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "terms", $$v)
                              },
                              expression: "search.terms",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c("span", { staticClass: "divider" }),
                              _c("span", { staticClass: "divider" }),
                              _c(
                                "b-dropdown",
                                {
                                  ref: "dropdown",
                                  staticClass: "dropdown filter-dropdown",
                                  attrs: { boundary: "window" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _c("b-icon-funnel", {
                                              staticClass: "mr-2",
                                            }),
                                            _vm._v(
                                              _vm._s(_vm.filterInputLabel) + " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2411036029
                                  ),
                                },
                                [
                                  _c(
                                    "b-dropdown-form",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-items-start",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "20px",
                                            "font-weight": "300",
                                          },
                                        },
                                        [_vm._v("Filters")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "selecter-label" },
                                        [_vm._v("Document Type")]
                                      ),
                                      _c("multiselect", {
                                        attrs: {
                                          "hide-selected": true,
                                          options: _vm.types,
                                          "track-by": "type",
                                          label: "label",
                                          multiple: true,
                                          "show-labels": false,
                                          placeholder: "-- Select an Option --",
                                        },
                                        model: {
                                          value: _vm.search.types,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.search, "types", $$v)
                                          },
                                          expression: "search.types",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "selecter-label" },
                                        [_vm._v("Worker")]
                                      ),
                                      _c("multiselect", {
                                        attrs: {
                                          "hide-selected": true,
                                          options: _vm.workers,
                                          "track-by": "worker_id",
                                          label: "name",
                                          multiple: true,
                                          "show-labels": false,
                                          placeholder: "-- Select an Option --",
                                        },
                                        model: {
                                          value: _vm.search.workers,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.search, "workers", $$v)
                                          },
                                          expression: "search.workers",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "selecter-label" },
                                        [_vm._v("Created After")]
                                      ),
                                      _c("b-form-input", {
                                        ref: "date1",
                                        staticClass: "date-input",
                                        attrs: {
                                          type: "date",
                                          "value-as-date": "",
                                          locale: "en",
                                        },
                                        model: {
                                          value: _vm.search.start_date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.search,
                                              "start_date",
                                              $$v
                                            )
                                          },
                                          expression: "search.start_date",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "selecter-label" },
                                        [_vm._v("Created Before")]
                                      ),
                                      _c("b-form-input", {
                                        ref: "date2",
                                        staticClass: "date-input",
                                        attrs: {
                                          type: "date",
                                          "value-as-date": "",
                                          locale: "en",
                                        },
                                        model: {
                                          value: _vm.search.end_date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.search,
                                              "end_date",
                                              $$v
                                            )
                                          },
                                          expression: "search.end_date",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-end mt-4",
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "avo-basic-btn mr-2",
                                              attrs: {
                                                variant: "outline-primary",
                                              },
                                              on: { click: _vm.clearFilters },
                                            },
                                            [_vm._v("Clear Filters")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "avo-primary-btn",
                                              attrs: {
                                                variant: "outline-primary",
                                              },
                                              on: { click: _vm.getDocuments },
                                            },
                                            [_vm._v("Apply")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.idType != "report"
                    ? _c(
                        "b-col",
                        {
                          staticClass: "text-right",
                          attrs: { sm: "12", md: "5", lg: "6" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.new-document",
                                  modifiers: { "new-document": true },
                                },
                              ],
                              staticClass: "avo-primary-btn mt-4 mt-md-0",
                              attrs: { variant: "outline-primary" },
                            },
                            [
                              _c("b-icon", {
                                staticClass: "mr-2",
                                attrs: { icon: "plus" },
                              }),
                              _vm._v("Add Document "),
                            ],
                            1
                          ),
                          _c("document-add", {
                            attrs: { idType: _vm.idType, id: _vm.id },
                            on: {
                              documentAdded: _vm.getDocuments,
                              docUploadError: _vm.alertUploadError,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm.mode === "inline"
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-between px-4" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "label-text",
                            staticStyle: {
                              "text-decoration": "underline var(--blue) 3px",
                              "text-underline-offset": "1.2rem",
                            },
                          },
                          [_vm._v(" Documents ")]
                        ),
                        _c("div", { staticClass: "avo-text-light" }, [
                          _vm._v("View all documents"),
                        ]),
                      ]
                    ),
                    _c("hr", { staticClass: "w-100" }),
                  ]),
                ],
                1
              )
            : _vm.mode === "table"
            ? _c(
                "div",
                { staticClass: "document-table" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { staticClass: "avo-header-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.idType == "report" ? "Exports" : "Documents"
                            ) +
                            " "
                        ),
                      ]),
                      _vm.idType != "report"
                        ? _c(
                            "b-col",
                            {
                              staticClass: "text-right",
                              attrs: { sm: "12", md: "5", lg: "6" },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.new-document",
                                      modifiers: { "new-document": true },
                                    },
                                  ],
                                  staticClass: "avo-primary-btn mt-4 mt-md-0",
                                  attrs: { variant: "outline-primary" },
                                },
                                [
                                  _c("b-icon", {
                                    staticClass: "mr-2",
                                    attrs: { icon: "plus" },
                                  }),
                                  _vm._v("Add Document "),
                                ],
                                1
                              ),
                              _c("document-add", {
                                attrs: { idType: _vm.idType, id: _vm.id },
                                on: {
                                  documentAdded: _vm.getDocuments,
                                  docUploadError: _vm.alertUploadError,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "document-table-rows" },
                    _vm._l(_vm.documents, function (document, index) {
                      return _c(
                        "b-row",
                        { key: document.document_id },
                        [
                          _c(
                            "b-col",
                            [
                              index > 0 ? _c("hr") : _vm._e(),
                              _c("document-card", {
                                key: document.document_id,
                                attrs: {
                                  document: document,
                                  mode: "table",
                                  idType: _vm.idType,
                                  id: _vm.id,
                                  hideDropdown: _vm.hideDropdown,
                                },
                                on: {
                                  refresh: function ($event) {
                                    return _vm.getDocuments()
                                  },
                                  docNotFoundError: _vm.alertDocNotFound,
                                  docDownloadError: _vm.alertDocError,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _vm.documents.length == 0
                    ? _c("div", { staticClass: "no-documents mb-4" }, [
                        _vm._v(" No documents "),
                      ])
                    : _vm._e(),
                  _vm.showPagination && _vm.documents.length >= 0
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("b-pagination", {
                                attrs: {
                                  "total-rows": _vm.totalRows,
                                  "per-page": _vm.perPageVar,
                                  align: "center",
                                },
                                model: {
                                  value: _vm.currentPage,
                                  callback: function ($$v) {
                                    _vm.currentPage = $$v
                                  },
                                  expression: "currentPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.mode === "list"
            ? [
                _c(
                  "div",
                  { staticClass: "p-5" },
                  [
                    _c(
                      "b-row",
                      _vm._l(_vm.documents, function (document) {
                        return _c(
                          "b-col",
                          {
                            key: document.document_id,
                            staticClass: "col-12 col-sm-6 col-md-4 col-lg-3",
                          },
                          [
                            _c("document-card", {
                              key: document.document_id,
                              attrs: {
                                document: document,
                                mode: "list",
                                idType: _vm.idType,
                                id: _vm.id,
                                hideDropdown: _vm.hideDropdown,
                              },
                              on: {
                                refresh: function ($event) {
                                  return _vm.getDocuments()
                                },
                                docNotFoundError: _vm.alertDocNotFound,
                                docDownloadError: _vm.alertDocError,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm.mode === "inline"
            ? [
                _c(
                  "div",
                  { staticClass: "pt-4 pr-4 pl-4" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          _vm._l(_vm.documents, function (document) {
                            return _c("document-card", {
                              key: document.document_id,
                              attrs: {
                                document: document,
                                mode: "inline",
                                idType: _vm.idType,
                                id: _vm.id,
                                hideDropdown: _vm.hideDropdown,
                              },
                              on: {
                                refresh: function ($event) {
                                  return _vm.getDocuments()
                                },
                                docNotFoundError: _vm.alertDocNotFound,
                                docDownloadError: _vm.alertDocError,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.documents.length == 0 &&
          _vm.documentsLoading &&
          _vm.mode != "table"
            ? _c("div", { staticClass: "no-documents" }, [
                _vm._v(" Fetching documents "),
              ])
            : _vm._e(),
          _vm.documents.length == 0 &&
          !_vm.documentsLoading &&
          _vm.mode != "table"
            ? _c("div", { staticClass: "no-documents" }, [
                _vm._v(" No documents found "),
              ])
            : _vm._e(),
          _vm.showPagination && _vm.documents.length > 0 && _vm.mode != "table"
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPageVar,
                          align: "center",
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }